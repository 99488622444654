import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { AppState } from '../../reducers'
import {
    //models
    SpecialtiesModal,

    // constants package
    SET_CATEGORIES,
    SET_CATEGORIES_SINGLE,

    // loading
    START_LOADING,
    STOP_LOADING,

    // constants alert
    SET_FAILELD,
    SET_SUCCESS,
    SET_SELECTED_CATEGORY_ID,
    SET_SELECTED_SERVICE_CATEGORY,
    REMOVE_SELECTED_SERVICE_CATEGORY_FOR_PRICES,
    SET_CATEGORY_PACKAGE,
    SET_CATEGORIES_FOR_FILTER,
    SET_CATEGORIES_FOR_PRICES,
    UPDATE_CATEGORY_FOR_PRICES
    
} from "../../../models";
import {
    getListCategoriesApi,
    getListCategoriesByAdminApi,
    addCategoryApi,
    getCategoryByIdApi,
    editCategoriesApi,
    deleteCategoryApi,
    addMasterCategoryToFranchiseApi,
    sortCategoriesApi,
    sortCategoriesForPricesApi,
    getServiceCategoryPackageApi,
    getListCategoriesForFilterApi,
    getListCategoriesForPricesApi
} from '../../../api';
import {
    startLoading,
    stopLoading
} from '../index'



// get list category
export const getCategoriesAction = (franchise_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListCategoriesApi(franchise_id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORIES, payload: response})
            }
            return response;
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get list category for filter
export const getCategoriesForFilterAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListCategoriesForFilterApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORIES_FOR_FILTER, payload: response})
            }
            return response;
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get list category for filter
export const getCategoriesForPricesAction = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListCategoriesForPricesApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORIES_FOR_PRICES, payload: response})
            }
            return response;
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// get list category
export const getCategoriesByAdminAction = (franchise_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getListCategoriesByAdminApi().xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORIES, payload: response})
            }
            return response;
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            return false;
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Add category
export const addCategoryAction = (data: FormData): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addCategoryApi(data).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category Added Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Add category
export const addMasterCategoryToFranchiseAction = (data: FormData): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            
            const response = await addMasterCategoryToFranchiseApi(data).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category Added Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Get category by id
export const getCategoryByIdAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getCategoryByIdApi(id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORIES_SINGLE, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


// Edit category
export const editCategoriesAction = (id: string, params: FormData): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response: any = await editCategoriesApi(id, params).xhr;

            // set account info
            if( response ){
                dispatch({type: UPDATE_CATEGORY_FOR_PRICES, payload: response})
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category Updated Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Edit category
export const sortCategories = (cat_id:string, sort_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response: any = await sortCategoriesApi(cat_id, sort_id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category Updated Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

// Edit category for prices
export const sortCategoriesForPrices = (cat_id:string, sort_id:string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response: any = await sortCategoriesForPricesApi(cat_id, sort_id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category Updated Successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


// delete category
export const deleteCategoryAction = (id: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {

        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deleteCategoryApi(id).xhr;

            // set account info
            if( response ){
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Category removed successfully'}})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const setSelectedCategoryIdAction = (id: any): ThunkAction<void, AppState, null, Action<string>> => {
    return (dispatch: any, getState: any) => {
        dispatch({type: SET_SELECTED_CATEGORY_ID, payload: id});
    }
};

export const setServiceCategory = (data: any, status: boolean, mode: string, categoryMode: string): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {
        // dispatch({type: SET_SELECTED_SERVICE_CATEGORY, payload: data});
        try{
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getServiceCategoryPackageApi(status, mode, categoryMode, data.id).xhr; // api for packages by service category

            // set account info
            if( response ){
                dispatch({type: SET_CATEGORY_PACKAGE, payload: response})
            }
            
        }catch(err){

            // show error
            dispatch({type: SET_FAILELD, payload: { message: err }})
            
        }finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
        
    }
};

export const removeServiceCategoryForPrices = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {
        dispatch({type: REMOVE_SELECTED_SERVICE_CATEGORY_FOR_PRICES});
    }
};

export const setServiceCategoryForPrices = (data: any): ThunkAction<void, AppState, null, Action<string>> => {
    return async(dispatch: any, getState: any) => {
        dispatch({type: SET_SELECTED_SERVICE_CATEGORY, payload: data});
    }
};