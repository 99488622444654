// get list category
import {ThunkAction} from "redux-thunk";
import {AppState} from "../../reducers";
import {Action} from "redux";
import {startLoading, stopLoading} from "..";
import {
    earningFilterModel,
    earningUpdateModel,
    GET_CLIENT_SESSION_LIST,
    GET_FRANCHISE_SESSION_EARNING,
    GET_SESSION_EARNING,
    GET_EARNING_FOR_GENERATE_REPORT,
    GET_EARNING_FOR_ANALYSIS,
    GET_DATA_FOR_CATEGORY_PACKAGE_SUMMARY,
    GET_DATA_FOR_CATEGORY_PACKAGE_REPORT_SUMMARY,
    GET_DATA_FOR_CLIENT_SESSION_DETAILS,
    GET_DATA_FOR_CLIENT_SESSION_REPORT_DETAILS,
    sessionFilters,
    sessionSorts,
    SET_FAILELD,
    SET_SUCCESS,
    START_LOADING,
    STOP_LOADING,
    UPDATE_SESSION_EARNING,
    GET_CLIENTS_COUNT,
    GET_CLIENTS_COUNT_EXCEL
} from "../../../models";
import {
    addNewSession,
    deleteSession,
    getAllSessionList,
    getClientSessionListApi,
    getFranchiseSessionEarnings,
    getOpportunitySessionList,
    getSessionById,
    getSessionEarnings,
    getSessionEarningsForReport,
    getEarningForGenerateAnalysisReportApi,
    getSessionEarningWithEarningCount,
    updateSession,
    updateSessionEarning,
    getDataForCategoryPackageSummaryApi,
    getDataForClientSessionDetailsApi,
    getDataForClientSessionDetailsReportApi,
    getDataForCategoryPackageSummaryReportApi,
    getClientCountsApi,
    getClientCountsExcelApi,
    checkForExpiredPackageApi,
    updateSessionTrainer,
    getAllSessionListWithoutPagination
} from "../../../api/sessions";
import {
    ADD_SESSION,
    CURRENT_SESSION,
    DELETE_SESSION,
    sessionParams,
    SET_SESSION_FILTERS,
    SET_SESSION_LIST,
    SET_SESSION_LIST_COMPLETED,
    UPDATE_SESSION,
} from "../../../models/app/sessions";

import {initialStateEarningList} from "../../reducers/app/sessions";


export const getAllSessionsAction = (status: string[] | any, type: 'all' | 'filter' = 'all', filters: sessionFilters, sorts: sessionSorts): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null);

            const response: any = await getAllSessionList(status, filters, sorts).xhr;
            // set account info
            let data
            if(response.page === 1) {
                data = response.data
            } else {
                data = [...getState().sessions.completed.data, ...response.data]
            }
            let obj = {
                lastPage: response.lastPage,
                page: response.page,
                perPage: response.perPage,
                total: response.total,
                data,
            }
            if (response) {
                if (type === 'filter') {
                    dispatch({type: SET_SESSION_LIST_COMPLETED, payload: obj})
                } else {
                    dispatch({type: SET_SESSION_LIST, payload: obj});
                    return response;
                }
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllSessionsPaginationAction = (status: string[] | any, type: 'all' | 'filter' = 'all', filters: sessionFilters, sorts: sessionSorts): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null);

            const response:any = await getAllSessionList(status, filters, sorts).xhr;
            // set account info
            if (response) {
                if(filters.page > 1) {
                    let data = [...getState().sessions.data.data, ...response.data]
                    let obj = { lastPage:response.lastPage, 
                        page: response.page, 
                        perPage: response.perPage, 
                        total:response.total, 
                        data: data}
                    dispatch({type: SET_SESSION_LIST, payload:obj});
                } else {
                    dispatch({type: SET_SESSION_LIST, payload: response})
                }
                // dispatch({type: SET_SESSION_LIST_COMPLETED, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllSessionsWithoutPaginationAction = (status: string[] | any, type: 'all' | 'filter' = 'all', filters: sessionFilters, sorts: sessionSorts): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null);
            const response:any = await getAllSessionListWithoutPagination(status, filters, sorts).xhr;
            // set account info
            if (response) {
                dispatch({type: SET_SESSION_LIST, payload: {lastPage: 1, page: 1, data:response}})
                // dispatch({type: SET_SESSION_LIST_COMPLETED, payload: response})
            }
        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getOpportunitySessionsAction = (oppId: number, status: string, page: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getOpportunitySessionList(oppId, status, page).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SESSION_LIST, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getCurrentSessionAction = (id: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getSessionById(id).xhr;

            // set account info
            if (response) {
                dispatch({type: CURRENT_SESSION, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const addSessionAction = (body: sessionParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await addNewSession(body).xhr;

            // set account info
            if (response) {
                startLoading(START_LOADING)(dispatch, getState, null)
                const currentSession = await getSessionById(response.id).xhr;
                dispatch({type: ADD_SESSION, payload: currentSession})
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session added successfully'}})
                return true;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const checkForExpiredPackageAction = (body: sessionParams): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            
            const response = await checkForExpiredPackageApi(body).xhr;

            // set account info
            if (response) {
                // startLoading(START_LOADING)(dispatch, getState, null)
                // const currentSession = await getSessionById(response.id).xhr;
                // dispatch({type: ADD_SESSION, payload: currentSession})
                // dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session added successfully'}})
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateSessionAction = (body: sessionParams, id: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateSession(body, id).xhr;

            // set account info
            if (response) {

                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session edited successfully'}})
                dispatch({type: UPDATE_SESSION, payload: response})
                return true;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const updateSessionTrainerAction = (body: sessionParams, id: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateSessionTrainer(body, id).xhr;

            // set account info
            if (response) {

                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session edited successfully'}})
                dispatch({type: UPDATE_SESSION, payload: response})
                return true;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
            return false;

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const deleteSessionAction = (id: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await deleteSession(id).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session removed successfully'}})
                dispatch({type: DELETE_SESSION, payload: id})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const updateSessionEarningAction = (id: number, body: earningUpdateModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await updateSessionEarning(id, body).xhr;

            // set account info
            if (response) {
                dispatch({type: SET_SUCCESS, payload: {success: true, message: 'Session Earning updated successfully'}})
                dispatch({type: UPDATE_SESSION_EARNING, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})
        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};


export const getAllSessionEarningAction = (earningFilters: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getSessionEarnings(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_SESSION_EARNING, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getEarningForGenerateReportAction = (earningFilters: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getSessionEarningsForReport(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_EARNING_FOR_GENERATE_REPORT, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getEarningForGenerateAnalysisReportAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getEarningForGenerateAnalysisReportApi(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_EARNING_FOR_GENERATE_REPORT, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};
export const getEarningForAnalysisAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getEarningForGenerateAnalysisReportApi(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_EARNING_FOR_ANALYSIS, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getDataForClientSessionDetailsAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getDataForClientSessionDetailsApi(earningFilters).xhr;

            // set account info
            
            
            if (response) {
                dispatch({type: GET_DATA_FOR_CLIENT_SESSION_DETAILS, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getDataForClientSessionDetailsReportAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getDataForClientSessionDetailsReportApi(earningFilters).xhr;

            // set account info
            
            
            if (response) {
                dispatch({type: GET_DATA_FOR_CLIENT_SESSION_REPORT_DETAILS, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getDataForCategoryPackageSummaryAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getDataForCategoryPackageSummaryApi(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_DATA_FOR_CATEGORY_PACKAGE_SUMMARY, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getDataForCategoryPackageSummaryReportAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            let response:any = await getDataForCategoryPackageSummaryReportApi(earningFilters).xhr;

            // set account info
            if (response) {
                // For filtering active packages only
                if(earningFilters.reportFor && earningFilters.reportFor === "activeChecked") {
                    let now = new Date();
                    now.setMinutes(0);
                    now.setSeconds(0);
                    now.setMilliseconds(0);
                    response = response.filter((obj: any) => new Date(obj.expire_time).getTime() > new Date(now).getTime());
                }
                dispatch({type: GET_DATA_FOR_CATEGORY_PACKAGE_REPORT_SUMMARY, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllSessionEarningTrainerAction = (earningFilters: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getSessionEarningWithEarningCount(earningFilters).xhr;

            // set account info
            if (response) {
                if(earningFilters.page > 1) {
                    let data = [...getState().sessions.sessionEarning.earnings, ...response.earnings]
                    let obj = { lastPage:response.lastPage, 
                        page: response.page, 
                        perPage: response.perPage, 
                        total:response.total, 
                        earnings: data, 
                        totalEarnings:response.totalEarnings}
                    dispatch({type: GET_SESSION_EARNING, payload:obj});
                } else {
                    dispatch({type: GET_SESSION_EARNING, payload: response})
                }
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getAllFranchiseSessionEarningAction = (franchiseId:number): any => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getFranchiseSessionEarnings(franchiseId).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_FRANCHISE_SESSION_EARNING, payload: response});
                return response;
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getClientSessionListAction = (clientId: number): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response = await getClientSessionListApi(clientId).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_CLIENT_SESSION_LIST, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getClientCountsAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getClientCountsApi(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_CLIENTS_COUNT, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const getClientCountsExcelAction = (earningFilters?: earningFilterModel): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {
        try {
            startLoading(START_LOADING)(dispatch, getState, null)
            const response:any = await getClientCountsExcelApi(earningFilters).xhr;

            // set account info
            if (response) {
                dispatch({type: GET_CLIENTS_COUNT_EXCEL, payload: response})
            }

        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const clearEarningSessions = (): ThunkAction<void, AppState, null, Action<string>> => {
    return async (dispatch: any, getState: any) => {

        try {
            dispatch({type: GET_SESSION_EARNING, payload: initialStateEarningList})
        } catch (err) {

            // show error
            dispatch({type: SET_FAILELD, payload: {message: err}})

        } finally {
            stopLoading(STOP_LOADING)(dispatch, getState, null)
        }
    };
};

export const setSessionFiltersAction = (filter: any) => {
    return async (dispatch: any) => {
        dispatch({type: SET_SESSION_FILTERS, payload: filter})
    };
};
