import { combineReducers } from "redux";
import {

    // models
    CategoriesModal,
    SET_CATEGORIES_SINGLE,
    CategoriesModalSingle,

    // constants
    SET_CATEGORIES,
    CategoriesActionType,
    SET_SELECTED_CATEGORY_ID,
    SET_SELECTED_SERVICE_CATEGORY,
    REMOVE_SELECTED_SERVICE_CATEGORY_FOR_PRICES,
    SET_CATEGORIES_FOR_FILTER,
    SET_CATEGORIES_FOR_PRICES,
    UPDATE_CATEGORY_FOR_PRICES
    
} from "../../../models";
import { findIndex as lodashFindIndex } from "lodash";

const initialStateLanguage: CategoriesModal[] = []
const initialStateCategorySingle: CategoriesModalSingle = {
    id: 0,
    name: "",
    status: true,
    image_url: ""
}
const initStateSelectedCategoryId: number = null
const initStateSelectedServiceCategoryId: any = {}


export default combineReducers({
    data: (state: CategoriesModal[] = initialStateLanguage, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_CATEGORIES:
                return action.payload;

            default:
                return state;
        }
    },
    categoryForFilter: (state: CategoriesModal[] = initialStateLanguage, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_CATEGORIES_FOR_FILTER:
                return action.payload;

            default:
                return state;
        }
    },
    categoryForPrices: (state: CategoriesModal[] = initialStateLanguage, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_CATEGORIES_FOR_PRICES:
                return action.payload;
            case UPDATE_CATEGORY_FOR_PRICES:                
                const index = lodashFindIndex(state, {id: action.payload.id});
                if (index > -1) {
                    state[index] = Object.assign({}, state[index], action.payload);
                }
                return state;

            default:
                return state;
        }
    },
    categoryById: (state: CategoriesModalSingle = initialStateCategorySingle, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_CATEGORIES_SINGLE:
                return action.payload;

            default:
                return state;
        }
    },
    selectedCategoryId :(state: any = initStateSelectedCategoryId, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_SELECTED_CATEGORY_ID:
                return action.payload;

            default:
                return state;
        }
    },
    selectedServiceCategory :(state: any = initStateSelectedServiceCategoryId, action: CategoriesActionType) => {
        switch (action.type) {
            case SET_SELECTED_SERVICE_CATEGORY:
                return action.payload;
            case REMOVE_SELECTED_SERVICE_CATEGORY_FOR_PRICES:
                return initStateSelectedServiceCategoryId

            default:
                return state;
        }
    } 
});
